<template>
  <div>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="text-h5">Credit</div>

    <div class="text-right">
      <v-btn
          color="primary"
          class="mt-2"
          @click="addCredit()"
      >
        Add Credit
        <v-icon class="pl-3" dark>
          mdi-plus
        </v-icon>
      </v-btn>
    </div>

    <!-- START DATA TABLE -->
    <v-data-table
        class="mt-5"
        :items="filteredData"
        :headers="tableHeader"
    >
      <template v-slot:top>
        <v-expansion-panels
            v-model="panels"
            multiple
            flat
            class="mb-3"
        >
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Summary</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Summary -->
              <v-row>
                <v-col cols="0" md="1"></v-col>
                <v-col cols="12" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Total Current</div></v-card-title>
                    <v-card-subtitle>{{totalCurrent | formattedAsCurrency}}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Current Long Term</div></v-card-title>
                    <v-card-subtitle>{{ totalCurrentLongTerm | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Current Rolling</div></v-card-title>
                    <v-card-subtitle>{{ totalCurrentRolling | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Current Cards</div></v-card-title>
                    <v-card-subtitle>{{ totalCurrentCard | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Current Loans</div></v-card-title>
                    <v-card-subtitle>{{ totalCurrentLoan | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="0" md="1"></v-col>
                <v-col cols="12" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Total Statement</div></v-card-title>
                    <v-card-subtitle>{{ totalStatement | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Statement Long Term</div></v-card-title>
                    <v-card-subtitle>{{ totalStatementLongTerm | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Statement Rolling</div></v-card-title>
                    <v-card-subtitle>{{ totalStatementRolling | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Statement Cards</div></v-card-title>
                    <v-card-subtitle>{{ totalStatementCard | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Statement Loans</div></v-card-title>
                    <v-card-subtitle>{{ totalStatementLoan | formattedAsCurrency }}</v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Filtering</v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Filtering -->
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                      :items="options"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.period"
                      label="From"
                      @input="updatePeriod"
                  />
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="[{id: 1, text: 'Active'},{id: 0, text: 'Archived'}]"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.status"
                      label="Status" />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="filtering.search"
                      label="Search by description"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="updateBalances(item)"
        >
          mdi-cash
        </v-icon>

        <v-icon
            small
            class="mr-2"
            @click="editCredit(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
            small
            class="mr-2"
            @click="deleteCredit(item)"
        >
          mdi-delete
        </v-icon>

        <v-icon
            small
            @click="showUpload(item)"
        >
          mdi-cloud-upload
        </v-icon>
      </template>

    </v-data-table>
    <!-- END DATA TABLE -->

    <!-- START BALANCES CARD -->
    <v-dialog
        v-model="updateMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Credit Account Balance</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-select
                    :items="[{id: 'periodic', text: 'Periodic'}, {id: 'statement',text: 'Montly Statement'}]"
                    item-value="id"
                    item-text="text"
                    v-model="updateData.type"
                >
                </v-select>
              </v-col>
              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    prepend-inner-icon="mdi-cash"
                    label="Amount*"
                    required
                    v-model="updateData.amount"
                    type="number"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  md="6"
              >
                <v-menu
                    v-model="paymentDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="Date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        :value="updateData.date"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="updateData.date"
                      no-title
                      @input="paymentDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="updateMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveBalance()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END BALANCE CARD -->

    <!-- UPLOAD STATEMENTS -->
    <v-dialog
        v-model="uploadMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Credit Account Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                    show-size
                    label="File input"
                    @change="selectFile"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="uploadMode = false"
          >
            Close
          </v-btn>
          <v-btn color="success" dark small @click="upload">
            Upload
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END UPLOAD STATEMENTS -->

    <!-- START ADD/EDIT CARD -->
    <v-dialog
        v-model="editMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Credit Account Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="9"
              >
                <v-text-field
                    label="Description *"
                    required
                    v-model="editData.description"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-text-field
                    prepend-inner-icon="mdi-cash"
                    label="Balance *"
                    required
                    v-model="editData.balance"
                    type="number"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-select
                    label="Type"
                    :items="['Card', 'Loan']"
                    v-model="editData.type"
                >
                </v-select>
              </v-col>

              <v-col
                  cols="12"
                  md="3"
              >
                <v-select
                    label="Term"
                    :items="['Rolling', 'Long Term']"
                    v-model="editData.term"
                >
                </v-select>
              </v-col>

              <v-col
                  cols="12"
                  md="6"
              >
                <v-text-field
                    disabled
                    :value="editData.color"
                    label="Color*"
                >
                </v-text-field>
                <v-color-picker
                    v-model="editData.color"
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                ></v-color-picker>
              </v-col>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCredit()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END ADD/EDIT CARD -->
  </div>
</template>

<script>
import { formatAsCurrency } from "../../services/utils";
import UploadService from "../../services/UploadFileService";

export default {
  name: "Credit",
  beforeMount() {
    this.$store.dispatch('fetchCreditPeriods').then(() => {
      if (this.filtering.period == '' && this.options.length > 0) {
        this.filtering.period = this.options[0].id;
        this.$store.dispatch('fetchCredit', this.filtering.period);
      }
    });

    this.$store.dispatch('fetchAccounts');
  },
  mounted() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        this.panels = [];
        break;
      case 'md':
      case 'lg':
      case 'xl':
        this.panels = [0,1];
        break;
    }
  },
  data() {
    return {
      uploadMode: false,
      panels: [],
      paymentDateMenu: false,
      filtering: {
        period: '',
        search: '',
        status: 1
      },
      perPage: '25',
      perPageOptions: [10,25,50,100,-1],
      tableHeader: [
        {
          value: 'description',
          text: 'Description'
        }, {
          value: 'type',
          text: 'Type',
        }, {
          value: 'term',
          text: 'Term'
        }, {
          value: 'balance_date',
          text: 'Last Statement Date'
        }, {
          value: 'balance',
          text: 'Statement Balance',
          align: 'end'
        }, {
          value: 'current',
          text: 'Current',
          align: 'end'
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      mobileWidth: 767,
      editMode: false,
      editData: {
        id: 0,
        description: '',
        type: 'Card',
        term: 'Rolling',
        balance: 0,
        plaid_account_id: '',
        color: "#FF0000"
      },
      updateMode: false,
      updateData: {
        id: 0,
        type: {id: 'periodic', text: 'Periodic'},
        amount: '',
        date: ''
      },
      isShown: false,

      fileCreditId: 0,
      currentFile: undefined,
      progress: 0,
      message: "",

      fileInfos: [],
    }
  },

  computed: {
    options() {
      return this.$store.state.credit.periodsOptions;
    },

    creditData() {
      return this.$store.state.credit.credit.data;
    },

    loading() {
      return this.$store.state.credit.loading || this.$store.state.accounts.loading;
    },

    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },

    filteredData() {
      return this.creditData
          .filter((cd) => (this.filtering.status == 2 || cd.active == this.filtering.status))
          .filter((cd) => {
            return cd.description.toLowerCase().includes(this.filtering.search.toLowerCase());
          });
    },

    totalStatement() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.balance_raw);
      return Math.round(total * 100) / 100;
    },

    totalCurrent() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.current_raw);
      return Math.round(total * 100) / 100;
    },

    totalStatementLongTerm() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.term == 'Long Term') {
          total += r.balance_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalCurrentLongTerm() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.term == 'Long Term') {
          total += r.current_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalStatementRolling() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.term == 'Rolling') {
          total += r.balance_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalCurrentRolling() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.term == 'Rolling') {
          total += r.current_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalStatementCard() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.type == 'Card') {
          total += r.balance_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalCurrentCard() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.type == 'Card') {
          total += r.current_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalStatementLoan() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.type == 'Loan') {
          total += r.balance_raw
        }
      });
      return Math.round(total * 100) / 100;
    },

    totalCurrentLoan() {
      let total = 0;
      this.filteredData.forEach((r) => {
        if (r.type == 'Loan') {
          total += r.current_raw
        }
      });
      return Math.round(total * 100) / 100;
    },
  },

  filters: {
    formattedAsCurrency(x) {
      return formatAsCurrency(x)
    }
  },

  methods: {
    showUpload(c) {
      this.fileCreditId = c.id;
      this.uploadMode = true;
      this.currentFile = null;
      this.progress = 0;
      this.message = '';
    },
    updateBalances(row) {
      this.updateData = {
        id: row.id,
        type: 'periodic',
        amount: '',
        date: ''
      }

      this.updateMode = true;
    },

    saveBalance() {
      this.$store.dispatch('updateBalance', this.updateData);
      this.updateMode = false;
    },

    addCredit() {
      this.editData = {
        id: 0,
        description: '',
        type: 'Card',
        term: 'Rolling',
        balance: 0,
        plaid_account_id: '',
        color: "#FF0000"
      }

      this.editMode = true;
    },

    editCredit(c) {
      this.editData = {
        id: c.id,
        description: c.description,
        type: c.type,
        term: c.term,
        balance: c.balance_raw,
        plaid_account_id: c.plaid_account_id,
        color: c.color ? c.color : '#FF0000'
      }

      this.editMode = true;
    },

    saveCredit() {
      if (this.editData.id > 0) {
        this.$store.dispatch('updateCredit', this.editData);
      } else {
        this.$store.dispatch('addCredit', this.editData);
      }

      this.editMode = false;
    },

    deleteCredit(c) {
      if (c.active == 0) {
        this.$store.dispatch('unDeleteCredit', c.id);
      } else {
        this.$store.dispatch('deleteCredit', c.id);
      }
    },

    updatePeriod() {
      this.$store.dispatch('fetchCredit', this.filtering.period);
    },

    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },

    upload() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }

      this.message = "";

      UploadService.upload(this.fileCreditId, this.currentFile, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
          .then((response) => {
            this.message = response.data.message;
            this.uploadMode = false;
          })
          .then((files) => {
            this.fileInfos = files.data;
          })
          .catch(() => {
            this.progress = 0;
            this.message = "Could not upload the file!";
            this.currentFile = undefined;
          });
    },
  }
}
</script>

<style scoped>

</style>