import axios from 'axios'

class UploadFilesService {
    upload(id, file, onUploadProgress) {
        let formData = new FormData();

        formData.append("statement", file);
        return axios.post(process.env.VUE_APP_ROOT_API + '/credit/' + id + '/transactions' , formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'bearer ' + sessionStorage.getItem('token')
            },
            onUploadProgress: onUploadProgress
        });
    }
}

export default new UploadFilesService();